import React, { useEffect } from "react";
import BulbIcon from "../../static/icons/bulb.svg";
import LogoIcon from "../../static/icons/logo.svg";
import SuccessIcon from "../../static/icons/success.svg";
import { navigate } from "gatsby";

const Confirmation = () => {
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			navigate("/");
		}, 14 * 1000);

		return () => clearTimeout(timeoutId);
	}, []);

	return (
		<div className="min-h-[100vh] bg-white flex items-center justify-center flex-col px-[15px] gap-[15px]">
			<SuccessIcon />
			<div className="text-center">
				<h1 className="text-text-primary text-[18px] font-medium mb-[5px]">
					Withdrawal processed successfully
				</h1>
				<a href="/" className="text-text-secondary text-[14px]">
					I'm done here...
				</a>
			</div>
			<div className="bg-[#5835FF1A] py-[10px] px-[16px] text-center rounded-lg flex gap-3">
				<BulbIcon />
				<p className="max-w-[230px] text-justify text-[12px] text-primary">
					Please note that withdrawal takes 24hrs and rates might change at actual time of withdrawal
				</p>
			</div>
			<p className="flex items-end gap-1 text-[#232323] text-[14px] leading-[20px]">
				Withdraw other crypto on
				<a className="mb-[0.2rem]" href="/" target="_blank">
					<LogoIcon />
				</a>
			</p>
		</div>
	);
};

export default Confirmation;
